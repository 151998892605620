<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">庫存異動記錄</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <!-- <div v-show="!grid.isEditing && grid.isRowSelected" id="batch-dropdown" class="dropdown ml-auto mr-2 sm:ml-0">
          <button
            class="dropdown-toggle button px-2 box bg-blue-500 text-white flex items-center"
          >
            <FontAwesome icon="tasks" type="fas" class="w-4 h-4 mr-1" />
            群組操作
          </button>
          <div class="dropdown-box">
            <div class="dropdown-box__content box dark:bg-dark-1 p-2 w-32">
              <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.removeSelectedRows"
              >
                <FontAwesome icon="trash" type="fas" class="w-4 h-4 mr-2" /> 刪除
              </span>
              <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.onPrint({ mode: 'selected' })"
              >
                <FontAwesome icon="print" type="fas" class="w-4 h-4 mr-2" /> 列印
              </span>
              <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.onExport({ type: 'csv', mode: 'selected' })"
              >
                <FontAwesome icon="file-csv" type="fas" class="w-4 h-4 mr-2" /> 匯出 CSV
              </span>
              <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.onExport({ type: 'html', mode: 'selected' })"
              >
                <FontAwesome icon="file" type="fas" class="w-4 h-4 mr-2" /> 匯出 HTML
              </span>
            </div>
          </div>
        </div> -->
        <button
          v-show="grid.canCreateRow && !grid.isEditing"
          class="button text-white bg-theme-1 shadow-md mr-2 flex items-center"
          @click="grid.addNewRow"
        >
          <FontAwesome icon="plus" type="fas" class="w-4 h-4 mr-1" />
          新增
        </button>
      </div>
    </div>
    <div
      class="flex flex-col sm:flex-row sm:items-end xl:items-start mt-2 bg-white p-1 rounded-lg"
    >
      <div class="p-1 mr-3 flex items-center border rounded-lg border-gray-400">
        <FontAwesome
          class="w-6 mx-1 text-theme-1"
          icon="calendar"
        ></FontAwesome>
        <vxe-input
          class="w-32"
          v-model="startDateFilter"
          clearable
          placeholder="開始日期"
          type="date"
          @change="grid.refresh"
        ></vxe-input>
        <FontAwesome class="h-2 mx-1 text-gray-400" icon="minus"></FontAwesome>
        <vxe-input
          class="w-32"
          v-model="endDateFilter"
          clearable
          placeholder="結束日期"
          type="date"
          @change="grid.refresh"
        ></vxe-input>
      </div>
      <div
        class="p-1 w-64 mr-3 flex items-center border rounded-lg border-gray-400"
      >
        <FontAwesome
          class="w-6 mx-1 text-theme-1"
          icon="warehouse"
        ></FontAwesome>
        <SelectBox
          :transfer="true"
          :dropdownWidth="300"
          v-model="warehouseFilter"
          v-bind="warehouseSelectOptions"
          @change="grid.refresh"
        />
      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-2">
      <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
        <div class="xl:flex sm:mr-auto">
          <div class="sm:flex items-center ml-1 sm:mr-2 mt-2 xl:mt-0">
            <input
              type="text"
              class="input w-full sm:w-40 xxl:w-full mt-2 sm:mt-0 border"
              v-model.trim="grid.keyword"
              placeholder="請輸入關鍵字"
              @keyup.enter="grid.refresh"
            />
          </div>
          <div class="mt-2 xl:mt-0">
            <button
              type="button"
              class="button w-full sm:w-16 bg-theme-1 text-white"
              @click="grid.refresh"
            >
              搜尋
            </button>
          </div>
        </div>
        <div class="xl:flex xl:mt-2 sm:mt-0">
          <!-- <button
            class="button w-1/2 sm:w-auto flex items-center border text-gray-700 dark:bg-dark-5 dark:text-gray-300"
            @click="grid.onPrint({ mode: 'curret' })"
          >
            <FontAwesome icon="print" class="w-4 h-4 mr-2" /> 列印
          </button> -->
          <div class="dropdown w-1/2 sm:w-auto ml-2">
            <button
              class="dropdown-toggle button w-full sm:w-auto flex items-center border text-gray-700 dark:bg-dark-5 dark:text-gray-300"
            >
              <FontAwesome icon="file-export" class="w-4 h-4 mr-2" /> 匯出
              <ChevronDownIcon class="w-4 h-4 ml-auto sm:ml-2" />
            </button>
            <div class="dropdown-box">
              <div class="dropdown-box__content box dark:bg-dark-1 p-2">
                <a
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="grid.onExport({ type: 'csv' })"
                >
                  <FontAwesome icon="file-csv" class="w-4 h-4 mr-2" /> CSV
                </a>
                <!-- <a
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="grid.onExport({ type: 'html' })"
                >
                  <FontAwesome icon="file" type="fas" class="w-4 h-4 mr-2" /> HTML
                </a> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="overflow-x-auto scrollbar-hidden mt-2">
        <grid
          ref="grid"
          v-bind="gridOptions"
          @removeSelectedRows="onGridRemoveSelectedRows"
        >
          <template #modal="{ row }">
            <vxe-form class="ml-3" v-bind="formOptions" :data="row"> </vxe-form>
          </template>
        </grid>
      </div>
    </div>
    <!-- END: HTML Table Data -->
  </div>
</template>

<script lang="ts">
import CloudFun, {
  Condition,
  defineComponent,
  Operator,
  ref
} from "@cloudfun/core";
import Grid, { GridOptions } from "@/cloudfun/components/Grid.vue";
import { VxeFormProps } from "vxe-table";
import formatDate from "xe-utils/toDateString";
import SelectBox, {
  SelectBoxOptions
} from "@/cloudfun/components/SelectBox.vue";

export default defineComponent({
  components: {
    Grid,
    SelectBox
  },
  setup() {
    const model = CloudFun.current?.model;
    const startDateFilter = ref<Date>();
    const endDateFilter = ref<Date>();
    const warehouseFilter = ref<number>();
    const grid = ref<any>({});
    var printColumns = [
      { field: "Time" },
      { field: "TypeName" },
      { field: "Product.Name" },
      { field: "ImportWarehouse.Name" },
      { field: "ExportWarehouse.Name" },
      { field: "Quantity" }
    ];

    const gridOptions: GridOptions = {
      title: "操作紀錄",
      canCreate: false,
      canUpdate: false,
      canDelete: false,
      multiselect: false,
      toolbarConfig: {
        custom: true,
        refresh: true
      },
      printConfig: {
        sheetName: "操作紀錄",
        columns: printColumns,
        modes: ["current", "selected", "all"]
      },
      exportConfig: {
        filename: "操作紀錄",
        type: "csv",
        types: ["html", "csv"],
        mode: "all",
        modes: ["current", "selected", "all"],
        columns: printColumns
      },
      columns: [
        {
          field: "Time",
          title: "時間",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          formatter: ({ cellValue }) =>
            formatDate(new Date(cellValue), "yyyy/MM/dd HH:mm:ss")
        },
        {
          field: "Type",
          title: "類型",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          formatter: ({ cellValue }) =>
            model
              ? Object.values(model.enums.InventoryRecordType).find(
                  e => e.Value === cellValue
                )?.Name
              : undefined
        },
        {
          field: "Product.Number",
          title: "品號",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Product.Name",
          title: "產品",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "ImportWarehouse.Name",
          title: "進貨倉庫",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "ExportWarehouse.Name",
          title: "出貨倉庫",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Quantity",
          title: "數量",
          showHeaderOverflow: true,
          showOverflow: true,
          resizable: false
        }
      ],
      promises: {
        query: model
          ? params => {
              if (!params.condition) params.condition = new Condition();
              if (startDateFilter.value)
                params.condition.and(
                  "Time",
                  Operator.GreaterThanOrEqual,
                  startDateFilter.value
                );
              if (endDateFilter.value) {
                const value =
                  typeof endDateFilter.value === "string"
                    ? new Date(endDateFilter.value)
                    : endDateFilter.value;
                params.condition.and(
                  "Time",
                  Operator.LessThanOrEqual,
                  new Date(value.setDate(value.getDate() + 1))
                );
              }
              if (warehouseFilter.value)
                params.condition.and(
                  new Condition(
                    "ImportWarehouse.Id",
                    Operator.Equal,
                    warehouseFilter.value
                  ).or(
                    "ExportWarehouse.Id",
                    Operator.Equal,
                    warehouseFilter.value
                  )
                );
              if (!params.sortings || params.sortings.length === 0)
                params.sortings = [{ column: "CreatedTime", order: 1 }];
              return model.dispatch("inventoryRecord/query", params);
            }
          : undefined,
        queryAll: model
          ? () => model.dispatch("inventoryRecord/query")
          : undefined
      },
      modalConfig: { width: 700 }
    };

    const formOptions: VxeFormProps = {
      titleWidth: 100,
      titleAlign: "right",
      items: [
        {
          field: "Time",
          title: "時間",
          span: 12,
          itemRender: {
            name: "$input",
            props: { type: "datetime", readonly: true }
          }
        },
        {
          field: "Type",
          title: "類別",
          span: 12,
          itemRender: {
            name: "$select",
            props: { readonly: true, disabled: true },
            options: model
              ? Object.values(model.enums.InventoryRecordType).map(e => {
                  return { label: e.Name, value: e.Value };
                })
              : []
          }
        },
        {
          field: "Product.Name",
          title: "產品",
          span: 12,
          itemRender: { name: "$input", props: { readonly: true } }
        },
        {
          field: "Quantity",
          title: "數量",
          span: 12,
          itemRender: { name: "$input", props: { readonly: true } }
        },
        {
          field: "ImportWarehouse.Name",
          title: "進貨倉庫",
          span: 12,
          itemRender: { name: "$input", props: { readonly: true } }
        },
        {
          field: "ExportWarehouse.Name",
          title: "出貨倉庫",
          span: 12,
          itemRender: { name: "$input", props: { readonly: true } }
        },
        {
          field: "PurchaseBill.Number",
          title: "進貨單",
          span: 12,
          itemRender: { name: "$input", props: { readonly: true } }
        },
        {
          field: "AdjustmentMemo.Number",
          title: "庫存調整單",
          span: 12,
          itemRender: { name: "$input", props: { readonly: true } }
        },
        {
          field: "Order.Number",
          title: "訂單",
          span: 12,
          itemRender: { name: "$input", props: { readonly: true } }
        }
      ]
    };

    const warehouseSelectOptions: SelectBoxOptions = {
      showSearch: true,
      rowId: "Id",
      placeholder: "選擇倉庫",
      textField: "Name",
      valueField: "Id",
      columns: [
        {
          field: "Number",
          title: "編號",
          showHeaderOverflow: true,
          showOverflow: true,
          width: 120,
          sortable: true
        },
        {
          field: "Name",
          title: "名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          treeNode: true
        }
      ],
      promises: {
        find: (value: any) => model!.dispatch("warehouse/find", value), // eslint-disable-line
        query: (params: any) => model!.dispatch("warehouse/query", params) // eslint-disable-line
      }
    };

    return {
      grid,
      gridOptions,
      formOptions,
      warehouseSelectOptions,
      startDateFilter,
      endDateFilter,
      warehouseFilter
    };
  },
  methods: {
    onGridRemoveSelectedRows(rows: any, callback: any) {
      cash("#batch-dropdown").dropdown("hide");
      callback();
    }
  }
});
</script>
